<template>
  <v-app
    id="inspire"
    class="loading-layout"
  >
    <v-app-bar
      app
      flat
      fixed
      class="loading-layout__topbar"
    >
      <TopbarLogo
        to=""
        class="ml-3"
      />
    </v-app-bar>

    <v-main class="loading-layout__body">
      <v-container class="fill-height justify-center">
        <NuxtPage />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import TopbarLogo from '~/components/topbars/topbar-logo';

export default {
  name: 'Loading',
  components: {
    TopbarLogo,
  },
  setup() {
    useWorkspace();
  },
};
</script>
<style lang="scss">
@use '~/assets/styles/tools/zazos-background' as bg;

.loading-layout {
  @include bg.zazos-background($important: true); // v-app override
  --max-width: 1160px;
  padding-inline: 16px;
}

.v-theme--dark.loading-layout {
  @include bg.zazos-background($important: true, $dark: true); // v-app override
}

.loading-layout__topbar {
  background-color: transparent !important;
  height: 48px;
}

.loading-layout__body {
  background-color: transparent !important;
}
</style>
